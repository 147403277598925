export default [
  {
    key: 'title',
    label: 'Judul',
    notes: [
      'Bisa berupa kode voucher, nama event, dll.',
      'Gunakan judul yang singkat agar text tidak terpotong saat ditampilkan di aplikasi.',
    ],
    required: true,
    maxLength: 15,
  },
  {
    key: 'percentage',
    label: 'Persentase',
    type: 'number',
    append: '%',
    min: 1,
    max: 100,
    notes: 'Persentase besaran potongan dari 1% - 100%.',
    required: true,
  },
  {
    key: 'maximum',
    label: 'Potongan maks.',
    type: 'number',
    prepend: 'Rp',
    min: 1,
    notes: 'Nilai potongan maksimum dari persentase potongan harga produk.',
    required: true,
  },
  {
    key: 'max_transaction',
    label: 'Kuota per pengguna',
    type: 'number',
    min: 1,
    notes: 'Batas penggunaan per pengguna.',
    required: true,
  },
  {
    key: 'quota',
    label: 'Kuota keseluruhan',
    type: 'number',
    min: 1,
    notes: 'Batas penggunaan untuk seluruh pengguna.',
    required: true,
  },
  {
    key: 'min_product_total',
    label: 'Min. transaksi',
    type: 'number',
    prepend: 'Rp',
    min: 1000,
    notes: 'Minimum transaksi berdasarkan total harga produk di keranjang',
    required: true,
  },
  {
    key: 'is_active',
    label: 'Status',
    type: 'radio',
    required: true,
    notes: 'Voucher akan dapat digunakan oleh pembeli apabila Aktif.',
    options: [
      {
        value: true,
        text: 'Aktif',
      },
      {
        value: false,
        text: 'Tidak aktif',
      },
    ],
  },
  {
    key: 'is_claimable',
    label: 'Dapat Diklaim',
    type: 'switch',
    notes: 'Aktifkan opsi ini jika voucher dapat diklaim oleh pengguna.',
  },
  {
    key: 'is_private',
    label: 'Privat',
    type: 'switch',
    notes: 'Aktifkan opsi ini jika voucher hanya ditujukan untuk pengguna tertentu dan tidak ditampilkan untuk umum.',
  },
  {
    key: 'terms',
    label: 'Syarat & Ketentuan',
    type: 'quill',
    notes: 'Akan ditampilkan di halaman voucher apabila ada.',
  },
  {
    type: 'section',
    title: 'Tipe',
    description: 'Voucher bisa diklaim berdasarkan semau brand atau brand tertentu. dan jika tidak di ubah maka default nya Semua Brand.',
  },
  {
    key: 'type',
    label: 'Tipe',
    type: 'radio',
    notes: [
      '<b>Semua Brand</b>: yang berarti semua brand boleh menggunakan voucher tersebut.',
      '<b>Whitelist</b>: hanya brand yang terdapat pada Daftar Brand yang <b>boleh</b> menggunakan voucher tersebut',
      '<b>Blacklist</b>: hanya brand yang terdapat pada Daftar Brand yang <b>tidak boleh</b> menggunakan voucher tersebut.',
    ],
    options: [
      {
        value: 'allow_all',
        text: 'Semua Brand',
      },
      {
        value: 'whitelist',
        text: 'Whitelist',
      },
      {
        value: 'blacklist',
        text: 'Blacklist',
      },
    ],
  },
  {
    key: 'brand_ids',
    label: 'Daftar Brand',
    type: 'multiple',
    text: 'name',
    required: true,
    actionSearch: 'brand/search',
    initialOptions: true,
  },
]
